.LayerHeading {
  font-size: var(
    --header-layer-font-size,
    var(--default-header-layer-font-size)
  );
  letter-spacing: var(
    --header-layer-letter-spacing,
    var(--default-header-layer-letter-spacing)
  );
  line-height: var(
    --header-layer-line-height,
    var(--default-header-layer-line-height)
  ); /* 15px; */
}

.LayerHeading .LayerHeadingContent {
  padding: 0px 40px 0px 45px;
  height: 60px;
  /* font-weight: bold; */
  font-family: var(
    --header-layer-name-font-family,
    var(--default-header-layer-name-font-family)
  );
  font-weight: var(
    --header-layer-name-font-weight,
    var(--default-header-layer-name-font-weight)
  );
  color: var(
    --header-layer-name-font-color,
    var(--default-header-layer-name-font-color)
  );
  word-break: break-word;
}

.LayerHeading.Scrollable {
  overflow-y: auto;
  height: 100%;
}

.LayerHeading .LayerHeadingDivider {
  height: 14px;
  margin: 0px 15px;
  border-left: 1px solid #000000;
}

.LayerHeading .LayerHeadingResults {
  /* font-weight: 100; */
  font-family: var(
    --header-layer-results-font-family,
    var(--default-header-layer-results-font-family)
  );
  font-weight: var(
    --header-layer-results-font-weight,
    var(--default-header-layer-results-font-weight)
  );
  color: var(
    --header-layer-results-font-color,
    var(--default-header-layer-results-font-color)
  );
}

.LayerHeading .MenuNavBar {
  margin: 0px;
  width: 100%;
}

.LayerHeading .MenuNavBar .MenuOptionLink,
.LayerHeading .MenuNavBar .MenuOptionLink:hover,
.LayerHeading .MenuNavBar .MenuOptionLink:focus {
  text-decoration: none;
  display: block;
  cursor: pointer;
  transition: all 0.3s;
}

/* Features */

.LayerHeading .MenuNavBar .MenuOptionLink {
  height: 60px;
  padding: 25px 17px 25px 45px;
  text-align: left;
  font-size: var(
    --list-organization-name-font-size,
    var(--default-list-organization-name-font-size)
  );
  letter-spacing: var(
    --list-organization-name-letter-spacing,
    var(--default-list-organization-name-letter-spacing)
  );
  line-height: var(
    --list-organization-name-line-height,
    var(--default-list-organization-name-line-height)
  ); /* 16px; */
  /* color: #000000;
  font-weight: bold; */
  font-family: var(
    --list-organization-name-font-family,
    var(--default-list-organization-name-font-family)
  );
  font-weight: var(
    --list-organization-name-font-weight,
    var(--default-list-organization-name-font-weight)
  );
  color: var(
    --list-organization-name-font-color,
    var(--default-list-organization-name-font-color)
  );
}

.LayerHeading .MenuNavBar .MenuOptionLink:hover {
  /* color: #ec2027; */
  color: var(
    --list-organization-name-hover-font-color,
    var(--default-list-organization-name-hover-font-color)
  );
}

.LayerHeading .MenuNavBar .MenuOptionLink p,
.LayerHeading .MenuNavBar .MenuOptionLink:hover p {
  /* font-weight: 100;
  color: #000000; */
  font-family: var(
    --list-organization-address-font-family,
    var(--default-list-organization-address-font-family)
  );
  font-weight: var(
    --list-organization-address-font-weight,
    var(--default-list-organization-address-font-weight)
  );
  color: var(
    --list-organization-address-font-color,
    var(--default-list-organization-address-font-color)
  );
}

.LayerHeading .MenuNavBar .MenuNavOption.active > .MenuOptionLink,
.LayerHeading .MenuOptionLink[aria-expanded="true"],
.LayerHeading .MenuOptionLink[aria-selected="true"] {
  /* color: #ec2027; */
  color: var(
    --list-organization-name-selected-font-color,
    var(--default-list-organization-name-selected-font-color)
  );
  /* border-left: 5px solid #ec2027; */
}

.LayerHeading .MenuOptionLink[aria-expanded="true"] {
  border-bottom: 1px solid rgba(0, 0, 0, 0.18);
}

.LayerHeading .MenuOptionLink[data-toggle="collapse"] {
  position: relative;
}

/* .LayerHeading .dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 2px;
  transform: translateY(-50%);
  color: #ec2027;
} */

.LayerHeading .MenuOptionLink[aria-selected="false"]::before {
  content: "";
  display: block;
  height: 1px;
  width: 20px;
  position: absolute;
  top: 0px;
  /*right: 20px; */
  box-sizing: border-box;
  border-bottom: 1px solid
    var(
      --list-organization-separator-color,
      var(--default-list-organization-separator-color)
    );
}

.LayerHeading .MenuOptionLink[aria-selected="true"].icon-arrow::after {
  content: "";
  display: block;
  height: 5px;
  width: 17px;
  margin-left: 5px;
  position: relative;
  background: transparent url(assets/icons/icon_arrow.png) no-repeat 0px 0px;
}

/* .LayerHeading .MenuOptionLink[aria-expanded="true"].dropdown-toggle::after, */
.LayerHeading .MenuOptionLink[aria-expanded="true"].icon-arrow::after {
  transform: rotate(-180deg);
  /* Fix margin when the icon is rotated */
  margin-left: 0px;
  margin-right: 8px;
}

/* Descriptions */

.LayerHeading .MenuNavBar .MenuTierContainer .MenuNavOption {
  /* border-left: 5px solid #ec2027; */
}

.LayerHeading .MenuNavBar .MenuTierContainer .MenuOptionContent {
  padding: 3px;
  padding-left: 40px;
  font-size: var(
    --list-organization-details-text-font-size,
    var(--default-list-organization-details-text-font-size)
  );
  letter-spacing: var(
    --list-organization-details-text-letter-spacing,
    var(--default-list-organization-details-text-letter-spacing)
  );
  line-height: var(
    --list-organization-details-text-line-height,
    var(--default-list-organization-details-text-line-height)
  );
  /* line-height: 1.1; /* 18px; */
  /* color: #000000;
  font-weight: 100; */
  font-family: var(
    --list-organization-details-text-font-family,
    var(--default-list-organization-details-text-font-family)
  );
  font-weight: var(
    --list-organization-details-text-font-weight,
    var(--default-list-organization-details-text-font-weight)
  );
  color: var(
    --list-organization-details-text-font-color,
    var(--default-list-organization-details-text-font-color)
  );
}

.LayerHeading .MenuNavBar .MenuTierContainer .MenuOptionContent p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.LayerHeading .MenuNavBar .MenuTierContainer .MenuOptionContent strong,
.LayerHeading .MenuNavBar .MenuTierContainer .MenuOptionContent b {
  font-size: var(
    --list-organization-details-field-font-size,
    var(--default-list-organization-details-field-font-size)
  );
  /* font-weight: bold; */
  font-family: var(
    --list-organization-details-field-font-family,
    var(--default-list-organization-details-field-font-family)
  );
  font-weight: var(
    --list-organization-details-field-font-weight,
    var(--default-list-organization-details-field-font-weight)
  );
  color: var(
    --list-organization-details-field-font-color,
    var(--default-list-organization-details-field-font-color)
  );
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .LayerHeading {
    font-size: var(
      --header-layer-mobile-font-size,
      var(--default-header-layer-mobile-font-size)
    );
    letter-spacing: var(
      --header-layer-mobile-letter-spacing,
      var(--default-header-layer-mobile-letter-spacing)
    );
    line-height: var(
      --header-layer-mobile-line-height,
      var(--default-header-layer-mobile-line-height)
    ); /* 20px; */
  }

  .LayerHeading .LayerHeadingContent {
    padding: 0px 25px;
  }

  /* Features */

  .LayerHeading .MenuNavBar .MenuOptionLink {
    padding: 0px 30px 0px;
    font-size: var(
      --list-organization-name-mobile-font-size,
      var(--default-list-organization-name-mobile-font-size)
    );
    letter-spacing: var(
      --header-layer-mobile-letter-spacing,
      var(--default-header-layer-mobile-letter-spacing)
    );
    line-height: var(
      --header-layer-mobile-line-height,
      var(--default-header-layer-mobile-line-height)
    ); /* 20px; */
  }

  /* Descriptions */

  .LayerHeading .MenuNavBar .MenuTierContainer .MenuOptionContent {
    padding: 25px 25px 0px;
    font-size: var(
      --list-organization-details-text-mobile-font-size,
      var(--default-list-organization-details-text-mobile-font-size)
    );
    letter-spacing: var(
      --list-organization-details-text-letter-spacing,
      var(--default-list-organization-details-text-letter-spacing)
    );
    line-height: var(
      --list-organization-details-text-mobile-line-height,
      var(--default-list-organization-details-text-mobile-line-height)
    );
    /* letter-spacing: 0.77px;
    line-height: 1.35; /* 21px; */
  }
}
