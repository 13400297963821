.Map {
  position: absolute;
  right: 0;
  /* Considering the LeftPane's width */
  width: calc(100% - 400px);
  height: 100%;
}

.zoomControl {
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  flex-direction: column;
}

.cluster-marker {
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.crime-marker {
  background: none;
  border: none;
}

.crime-marker img {
  width: 25px;
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .Map {
    top: 110px;
    width: 100%;
    /* Considering the heights of TopMenuBar, LayerHeading and BottomMenuBar */
    height: calc(100% - 160px);
  }
}
