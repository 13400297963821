/* The location pointed to by the popup tip. */
.popup-tip-anchor {
  height: 0;
  position: absolute;
  /* The max width of the info window. */
  width: 600px;
}

/* The bubble is anchored above the tip. */
.popup-bubble-anchor {
  position: absolute;
  width: 100%;
  bottom: /* TIP_HEIGHT= */ 8px;
  left: 0;
}

/* Draw the tip. */
.popup-bubble-anchor::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  /* Center the tip horizontally. */
  transform: translate(-50%, 0);
  /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
  width: 0;
  height: 0;
  /* The tip is 8px high, and 12px wide. */
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: /* TIP_HEIGHT= */ 8px solid white;
}

/* The popup bubble itself. */
.popup-bubble-content {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  /* Style the info window. */
  background-color: white;
  padding: 20px 22px;
  border-radius: 5px;
  font-family: sans-serif;
  overflow-y: auto;
  max-height: 400px;
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.5);
}

.popup-bubble-close-button-container {
  float: right;
  /* display: flex;
  justify-content: flex-end; */
}

/* The popup bubble close button */
.popup-bubble-close-button,
.popup-bubble-close-button:hover,
.popup-bubble-close-button:focus {
  border: 0;
  outline: none;
  background-color: transparent;
  color: inherit;
  font-family: Arial, Baskerville, monospace;
  font-size: 24px;
  cursor: pointer;
  opacity: 0.5;
  appearance: none;
  padding: 0;
  margin-bottom: 5px;
  line-height: 0.5;
}

.popup-bubble-close-button:hover,
.popup-bubble-close-button:focus {
  opacity: 0.7;
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .popup-bubble-content {
    padding: 10px 15px 10px;
  }
}
