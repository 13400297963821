.OrganizationsList .MenuNavBarContainer {
  background: transparent;
  transition: all 0.3s;
  overflow-y: auto;
  /* height: 100%; */
}

.OrganizationsList .MenuNavBar {
  padding: 34px 28px 50px 0px;
}

.OrganizationsList .MenuNavBar .MenuOptionLink,
.OrganizationsList .MenuNavBar .MenuOptionLink:hover,
.OrganizationsList .MenuNavBar .MenuOptionLink:focus {
  text-decoration: none;
  display: block;
  cursor: pointer;
  transition: all 0.3s;
}

/* Features */

.OrganizationsList .MenuNavBar .MenuOptionLink {
  padding: 25px 17px 25px 45px;
  text-align: left;
  font-size: var(
    --list-organization-name-font-size,
    var(--default-list-organization-name-font-size)
  );
  letter-spacing: var(
    --list-organization-name-letter-spacing,
    var(--default-list-organization-name-letter-spacing)
  );
  line-height: var(
    --list-organization-name-line-height,
    var(--default-list-organization-name-line-height)
  ); /* 16px; */
  /* color: #000000;
  font-weight: bold; */
  font-family: var(
    --list-organization-name-font-family,
    var(--default-list-organization-name-font-family)
  );
  font-weight: var(
    --list-organization-name-font-weight,
    var(--default-list-organization-name-font-weight)
  );
  color: var(
    --list-organization-name-font-color,
    var(--default-list-organization-name-font-color)
  );
}

.OrganizationsList .MenuNavBar .MenuOptionLink:hover {
  /* color: #ec2027; */
  color: var(
    --list-organization-name-hover-font-color,
    var(--default-list-organization-name-hover-font-color)
  );
}

.OrganizationsList .MenuNavBar .MenuOptionLink p,
.OrganizationsList .MenuNavBar .MenuOptionLink:hover p {
  /* font-weight: 100;
  color: #000000; */
  font-family: var(
    --list-organization-address-font-family,
    var(--default-list-organization-address-font-family)
  );
  font-weight: var(
    --list-organization-address-font-weight,
    var(--default-list-organization-address-font-weight)
  );
  color: var(
    --list-organization-address-font-color,
    var(--default-list-organization-address-font-color)
  );
}

.OrganizationsList .MenuNavBar .MenuNavOption.active > .MenuOptionLink,
.OrganizationsList .MenuOptionLink[aria-expanded="true"],
.OrganizationsList .MenuOptionLink[aria-selected="true"] {
  /* color: #ec2027; */
  color: var(
    --list-organization-name-selected-font-color,
    var(--default-list-organization-name-selected-font-color)
  );
  border-left: 5px solid
    var(
      --list-organization-left-border-color,
      var(--default-list-organization-left-border-color)
    );
}

.OrganizationsList .MenuOptionLink[aria-expanded="true"] p {
  display: none;
}

.OrganizationsList .MenuOptionLink[data-toggle="collapse"] {
  position: relative;
}

.OrganizationsList .DistanceFromUser {
  margin: 15px 0px 0px 10px;
}

/* .OrganizationsList .dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 2px;
  transform: translateY(-50%);
  color: #ec2027;
} */

.OrganizationsList .MenuOptionLink[aria-selected="false"]::before {
  content: "";
  display: block;
  height: 1px;
  width: 20px;
  position: absolute;
  top: 0px;
  /*right: 20px; */
  box-sizing: border-box;
  border-bottom: 1px solid
    var(
      --list-organization-separator-color,
      var(--default-list-organization-separator-color)
    );
}

.OrganizationsList .MenuOptionLink[aria-selected="true"].icon-arrow::after {
  content: "";
  display: block;
  height: 5px;
  width: 9px;
  position: absolute;
  top: 25px;
  right: 15px;
  background: transparent url(assets/icons/icon_arrow.png) no-repeat 0px 0px;
}

/* .OrganizationsList .MenuOptionLink[aria-expanded="true"].dropdown-toggle::after, */
.OrganizationsList .MenuOptionLink[aria-expanded="true"].icon-arrow::after {
  transform: rotate(-180deg);
}

/* Descriptions */

.OrganizationsList .MenuNavBar .MenuTierContainer .MenuNavOption {
  border-left: 5px solid
    var(
      --list-organization-left-border-color,
      var(--default-list-organization-left-border-color)
    );
}

.OrganizationsList .MenuNavBar .MenuTierContainer .MenuOptionContent {
  padding: 3px;
  padding-left: 40px;
  font-size: var(
    --list-organization-details-text-font-size,
    var(--default-list-organization-details-text-font-size)
  );
  letter-spacing: var(
    --list-organization-details-text-letter-spacing,
    var(--default-list-organization-details-text-letter-spacing)
  );
  line-height: var(
    --list-organization-details-text-line-height,
    var(--default-list-organization-details-text-line-height)
  ); /* 18px; */
  /* color: #000000;
  font-weight: 100; */
  font-family: var(
    --list-organization-details-text-font-family,
    var(--default-list-organization-details-text-font-family)
  );
  font-weight: var(
    --list-organization-details-text-font-weight,
    var(--default-list-organization-details-text-font-weight)
  );
  color: var(
    --list-organization-details-text-font-color,
    var(--default-list-organization-details-text-font-color)
  );
}

.OrganizationsList .MenuNavBar .MenuTierContainer .MenuOptionContent p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.OrganizationsList .MenuNavBar .MenuTierContainer .MenuOptionContent strong,
.OrganizationsList .MenuNavBar .MenuTierContainer .MenuOptionContent b {
  font-size: var(
    --list-organization-details-field-font-size,
    var(--default-list-organization-details-field-font-size)
  );
  /* font-weight: bold; */
  font-family: var(
    --list-organization-details-field-font-family,
    var(--default-list-organization-details-field-font-family)
  );
  font-weight: var(
    --list-organization-details-field-font-weight,
    var(--default-list-organization-details-field-font-weight)
  );
  color: var(
    --list-organization-details-field-font-color,
    var(--default-list-organization-details-field-font-color)
  );
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .OrganizationsList.TopDivider {
    border-top: 1px solid rgba(0, 0, 0, 0.11);
  }

  .OrganizationsList .MenuNavBar {
    padding: 24px 7px 50px 0px;
  }

  /* Features */

  .OrganizationsList .MenuNavBar .MenuOptionLink {
    padding-left: 30px;
    font-size: var(
      --list-organization-name-mobile-font-size,
      var(--default-list-organization-name-mobile-font-size)
    );
  }

  .OrganizationsList .MenuOptionLink[aria-selected="true"] {
    letter-spacing: var(
      --list-organization-name-mobile-letter-spacing,
      var(--default-list-organization-name-mobile-letter-spacing)
    );
    line-height: var(
      --list-organization-name-mobile-line-height,
      var(--default-list-organization-name-mobile-line-height)
    ); /* 21px; */
  }

  /* Decriptions */

  .OrganizationsList .MenuNavBar .MenuTierContainer .MenuOptionContent {
    padding-left: 30px;
    font-size: var(
      --list-organization-details-text-mobile-font-size,
      var(--default-list-organization-details-text-mobile-font-size)
    );
    letter-spacing: var(
      --list-organization-details-text-letter-spacing,
      var(--default-list-organization-details-text-letter-spacing)
    );
    line-height: var(
      --list-organization-details-text-mobile-line-height,
      var(--default-list-organization-details-text-mobile-line-height)
    );
    /* line-height: 1.35; /* 21px; */
  }
}
