.PlaceDescription {
  max-width: 378px;
  max-height: 400px;
  /* padding: 15px 0px 15px 15px; */
  padding-bottom: 5px;
  background-color: #ffffff;
  /* box-shadow: 0 0 18px 8px rgba(0, 0, 0, 0.08); */
  /* overflow-y: auto; */
  overflow-wrap: break-word;
  word-wrap: break-word; /* IE11 support*/
  font-size: var(
    --pin-details-organization-text-font-size,
    var(--default-pin-details-organization-text-font-size)
  );
  letter-spacing: var(
    --pin-details-organization-text-letter-spacing,
    var(--default-pin-details-organization-text-letter-spacing)
  );
  line-height: var(
    --pin-details-organization-text-line-height,
    var(--default-pin-details-organization-text-line-height)
  ); /* 15px; */
  /* color: #000000;
  font-family: 'Nexa';
  font-weight: normal; */
  font-family: var(
    --pin-details-organization-text-font-family,
    var(--default-pin-details-organization-text-font-family)
  );
  font-weight: var(
    --pin-details-organization-text-font-weight,
    var(--default-pin-details-organization-text-font-weight)
  );
  color: var(
    --pin-details-organization-text-font-color,
    var(--default-pin-details-organization-text-font-color)
  );
}

.PlaceDescription h3 {
  font-size: var(
    --pin-details-organization-name-font-size,
    var(--default-pin-details-organization-name-font-size)
  );
  padding-bottom: 0rem;
  /* color: #ec2027;
  font-weight: bold; */
  font-family: var(
    --pin-details-organization-name-font-family,
    var(--default-pin-details-organization-name-font-family)
  );
  font-weight: var(
    --pin-details-organization-name-font-weight,
    var(--default-pin-details-organization-name-font-weight)
  );
  color: var(
    --pin-details-organization-name-font-color,
    var(--default-pin-details-organization-name-font-color)
  );
}

.PlaceDescription .ExpandingArrowButton {
  display: none;
}

.PlaceDescription p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.PlaceDescription p strong,
.PlaceDescription p b {
  line-height: 1.2; /* 18px; */
  /* font-weight: bold; */
  font-family: var(
    --pin-details-organization-field-font-family,
    var(--default-pin-details-organization-field-font-family)
  );
  font-weight: var(
    --pin-details-organization-field-font-weight,
    var(--default-pin-details-organization-field-font-weight)
  );
  color: var(
    --pin-details-organization-field-font-color,
    var(--default-pin-details-organization-field-font-color)
  );
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .PlaceDescription {
    display: flex;
    justify-content: space-between;
    min-width: 250px;
    max-width: 250px;
    padding-bottom: 20px;
  }

  .PlaceDescription h3 {
    margin-bottom: 0px;
    font-size: var(
      --pin-details-organization-name-mobile-font-size,
      var(--default-pin-details-organization-name-mobile-font-size)
    );
    letter-spacing: 0.89px;
    /* line-height: 15px; */
    /* color: #000000; */
    color: var(
      --pin-details-organization-field-font-color,
      var(--default-pin-details-organization-field-font-color)
    );
  }

  .PlaceDescription .ExpandingArrowButton {
    display: block;
    border: 0;
    outline: none;
    background-color: transparent;
    margin: 0;
    padding: 0;
  }

  .PlaceDescription .ExpandingArrowButton::after {
    content: "";
    display: block;
    cursor: pointer;
    height: 5px;
    width: 12px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 15px;
    margin-right: 0px;
    position: relative;
    background: transparent url(assets/icons/icon_arrow.png) no-repeat 0px 0px;
  }

  .PlaceDescription p {
    display: none;
  }
}
