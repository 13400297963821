.TopMenuBar {
  padding: 0px 40px;
  min-height: 93px;
  background-color: var(
    --top-menu-bar-color,
    var(--default-top-menu-bar-color)
  );
}

.TopMenuBar .SearchBox {
  border: 2px white solid;
  box-shadow: unset;
  -moz-box-shadow: unset;
  -webkit-box-shadow: unset;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  padding: 0;
  background-color: transparent;
  color: white;
}

.TopMenuBar .TopMenuBarOption {
  text-decoration: none;
}

.TopMenuBar .TopMenuBarOption .icon-back::after {
  content: "";
  display: block;
  position: relative;
  height: var(
    --top-menu-bar-icon-back-height,
    var(--default-top-menu-bar-icon-back-height)
  );
  width: var(
    --top-menu-bar-icon-back-width,
    var(--default-top-menu-bar-icon-back-width)
  );
  margin-right: 16px;
  background: transparent
    var(--top-menu-bar-icon-back, var(--default-top-menu-bar-icon-back))
    no-repeat 0px 0px;
}

.TopMenuBar .TopMenuBarOption .icon-home::after {
  content: "";
  display: block;
  height: var(
    --top-menu-bar-icon-home-height,
    var(--default-top-menu-bar-icon-home-height)
  );
  width: var(
    --top-menu-bar-icon-home-width,
    var(--default-top-menu-bar-icon-home-width)
  );
  position: relative;
  margin-right: 16px;
  background: transparent
    var(--top-menu-bar-icon-home-off, var(--default-top-menu-bar-icon-home-off))
    no-repeat 0px 0px;
}

.TopMenuBar .TopMenuBarOption.active .icon-home::after {
  background: transparent
    var(--top-menu-bar-icon-home-on, var(--default-top-menu-bar-icon-home-on))
    no-repeat 0px 0px;
}

.TopMenuBar .TopMenuBarOption .icon-search::after {
  content: "";
  display: block;
  height: var(
    --top-menu-bar-icon-search-height,
    var(--default-top-menu-bar-icon-search-height)
  );
  width: var(
    --top-menu-bar-icon-search-width,
    var(--default-top-menu-bar-icon-search-width)
  );
  position: relative;
  color: #fff;
  background: transparent
    var(
      --top-menu-bar-icon-search-off,
      var(--default-top-menu-bar-icon-search-off)
    )
    no-repeat 0px 0px;
}

.TopMenuBar .TopMenuBarOption.active .icon-search::after {
  background: transparent
    var(
      --top-menu-bar-icon-search-on,
      var(--default-top-menu-bar-icon-search-on)
    )
    no-repeat 0px 0px;
}

.TopMenuBar .TopMenuBarOption .icon-hamburger::after {
  content: "";
  display: block;
  height: var(
    --top-menu-bar-icon-hamburger-height,
    var(--default-top-menu-bar-icon-hamburger-height)
  );
  width: var(
    --top-menu-bar-icon-hamburger-width,
    var(--default-top-menu-bar-icon-hamburger-width)
  );
  position: relative;
  background: transparent
    var(
      --top-menu-bar-icon-hamburger-off,
      var(--default-top-menu-bar-icon-hamburger-off)
    )
    no-repeat 0px 0px;
}

.TopMenuBar .TopMenuBarOption.active .icon-hamburger::after {
  background: transparent
    var(
      --top-menu-bar-icon-hamburger-on,
      var(--default-top-menu-bar-icon-hamburger-on)
    )
    no-repeat 0px 0px;
}

.active {
  color: var(--top-menu-bar-color) !important;
  cursor: pointer;
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .TopMenuBar {
    padding: 0px 25px;
    min-height: 50px;
  }
}
