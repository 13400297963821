.Settings {
  background: transparent;
  transition: all 0.3s;
}

.Settings .MenuNavBar {
  padding: 34px 28px 50px 0px;
}

.Settings .MenuNavBar .MenuOptionLink,
.Settings .MenuNavBar .MenuOptionLink:hover,
.Settings .MenuNavBar .MenuOptionLink:focus {
  text-decoration: none;
  display: block;
  cursor: pointer;
  transition: all 0.3s;
}

/* Settings */

.Settings .MenuNavBar .MenuOptionLink {
  padding: 17px;
  padding-left: 45px;
  text-align: left;
  font-size: var(
    --list-settings-option-font-size,
    var(--default-list-settings-option-font-size)
  );
  letter-spacing: var(
    --list-settings-option-letter-spacing,
    var(--default-list-settings-option-letter-spacing)
  );
  line-height: var(
    --list-settings-option-line-height,
    var(--default-list-settings-option-line-height)
  );
  /* color: #000000;
  font-weight: bold; */
  font-family: var(
    --list-settings-option-font-family,
    var(--default-list-settings-option-font-family)
  );
  font-weight: var(
    --list-settings-option-font-weight,
    var(--default-list-settings-option-font-weight)
  );
  color: var(
    --list-settings-option-font-color,
    var(--default-list-settings-option-font-color)
  );
}

.Settings .MenuNavBar .MenuOptionLink:hover {
  /* color: #ec2027; */
  color: var(
    --list-settings-option-hover-font-color,
    var(--default-list-settings-option-hover-font-color)
  );
}

.Settings .MenuNavBar .MenuNavOption.active > .MenuOptionLink,
.Settings .MenuOptionLink[aria-expanded="true"] {
  /* color: #ec2027; */
  color: var(
    --list-settings-option-selected-font-color,
    var(--default-list-settings-option-selected-font-color)
  );
}

.Settings .MenuOptionLink[data-toggle="collapse"] {
  position: relative;
}

/* .Settings .dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  color: #ec2027;
} */

.Settings .icon-arrow::after {
  content: "";
  display: block;
  height: 5px;
  width: 9px;
  position: absolute;
  top: 45%;
  right: 20px;
  background: transparent url(assets/icons/icon_arrow.png) no-repeat 0px 0px;
}

/* .Settings .MenuOptionLink[aria-expanded="true"].dropdown-toggle::after, */
.Settings .MenuOptionLink[aria-expanded="true"].icon-arrow::after {
  transform: rotate(-180deg);
}

/* Options */

.Settings .MenuNavBar .MenuTierContainer .MenuNavOption {
  /* border-left: 5px solid #ec2027; */
  border-left: 5px solid
    var(
      --list-settings-suboption-left-border-color,
      var(--default-list-settings-suboption-left-border-color)
    );
}

.Settings .MenuNavBar .MenuTierContainer .MenuOptionLink {
  padding: 3px;
  padding-left: 60px;
  letter-spacing: var(
    --list-settings-suboption-letter-spacing,
    var(--default-list-settings-suboption-letter-spacing)
  );
  line-height: 29px;
  /* font-weight: lighter; */
  font-family: var(
    --list-settings-suboption-font-family,
    var(--default-list-settings-suboption-font-family)
  );
  font-weight: var(
    --list-settings-suboption-font-weight,
    var(--default-list-settings-suboption-font-weight)
  );
  color: var(
    --list-settings-suboption-font-color,
    var(--default-list-settings-suboption-font-color)
  );
}

.Settings .MenuNavBar .MenuTierContainer .MenuOptionLink:hover {
  /* font-weight: bold; */
  font-family: var(
    --list-settings-suboption-hover-font-family,
    var(--default-list-settings-suboption-hover-font-family)
  );
  font-weight: var(
    --list-settings-suboption-hover-font-weight,
    var(--default-list-settings-suboption-hover-font-weight)
  );
}

.Settings
  .MenuNavBar
  .MenuTierContainer
  .MenuNavOption.active
  > .MenuOptionLink {
  /* font-weight: bold; */
  font-family: var(
    --list-settings-suboption-selected-font-family,
    var(--default-list-settings-suboption-selected-font-family)
  );
  font-weight: var(
    --list-settings-suboption-selected-font-weight,
    var(--default-list-settings-suboption-selected-font-weight)
  );
}

/* Option's Component */

.Settings .MenuNavBar .MenuTierContainer .MenuOptionContent {
  padding: 17px;
  padding-left: 45px;
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .Settings .MenuNavBar {
    padding: 24px 7px 50px 0px;
  }

  /* Settings */

  .Settings .MenuNavBar .MenuOptionLink {
    padding-left: 30px;
    font-size: var(
      --list-settings-option-mobile-font-size,
      var(--default-list-settings-option-mobile-font-size)
    );
    letter-spacing: var(
      --list-settings-option-mobile-letter-spacing,
      var(--default-list-settings-option-mobile-letter-spacing)
    );
    line-height: var(
      --list-settings-option-mobile-line-height,
      var(--default-list-settings-option-mobile-line-height)
    );
  }

  /* Options */

  .Settings .MenuNavBar .MenuTierContainer .MenuOptionLink {
    padding-left: 45px;
    letter-spacing: var(
      --list-settings-suboption-mobile-letter-spacing,
      var(--default-list-settings-suboption-mobile-letter-spacing)
    );
  }

  /* Option's Component */
  .Settings .MenuNavBar .MenuTierContainer .MenuOptionContent {
    padding-left: 30px;
  }
}
