.SearchControl {
  padding: 25px 40px;
  padding-left: 45px;
}

.SearchControl_Input,
.SearchControl_Input:focus {
  border: none;
  box-shadow: unset;
  appearance: none;
  outline: none;

  padding: 0;
  font-size: var(
    --search-input-font-size,
    var(--default-search-input-font-size)
  );
  letter-spacing: var(
    --search-input-letter-spacing,
    var(--default-search-input-letter-spacing)
  );
  line-height: 1.2; /* 19px; */
  padding-bottom: 20px;
  flex-grow: 1;
  /* font-weight: bold; */
  font-family: var(
    --search-input-font-family,
    var(--default-search-input-font-family)
  );
  font-weight: var(
    --search-input-font-weight,
    var(--default-search-input-font-weight)
  );
  color: var(--search-input-font-color, var(--default-search-input-font-color));
}

/* Remove IE10-11's "clear field" X button  */
.SearchControl_Input::-ms-clear {
  display: none;
}

.SearchControl_Input::placeholder {
  /* color: #000000; */
  color: var(
    --search-input-placeholder-font-color,
    var(--default-search-input-placeholder-font-color)
  );
  /* opacity: 0.5; */
}

.SearchControl .InputContainer {
  /* border-bottom: 1px solid #ec2027; */
  border-bottom: 1px solid
    var(
      --search-input-bottom-border-color,
      var(--default-search-input-bottom-border-color)
    );
  text-align: left;
}

.SearchControl .ClearSearchText {
  position: relative;
  width: 9px;
  margin-left: 5px;
}

.SearchControl .ClearSearchText .icon-search-x::after {
  content: "";
  display: block;
  height: 9px;
  width: 9px;
  top: 5px;
  position: absolute;
  background: transparent url(assets/icons/icon_search_x.png) no-repeat 0px 0px;
}

.SearchControl_SuggestionList {
  list-style: none;
  margin: 20px 0;
  padding: 0;
  font-size: var(
    --search-input-font-size,
    var(--default-search-input-font-size)
  );
  letter-spacing: var(
    --search-input-letter-spacing,
    var(--default-search-input-letter-spacing)
  );
  line-height: 1.25; /* 20px; /*30px;*/
  /* font-weight: 100; */
  font-family: var(
    --search-suggestion-list-font-family,
    var(--default-search-suggestion-list-font-family)
  );
  font-weight: var(
    --search-suggestion-list-font-weight,
    var(--default-search-suggestion-list-font-weight)
  );
  color: var(
    --search-suggestion-list-font-color,
    var(--default-search-suggestion-list-font-color)
  );
}

.SearchControl_Suggestion {
  margin-bottom: 15px;
  cursor: pointer;
}

.SearchControl_Suggestion:hover {
  /* color: #ec2027; */
  color: var(
    --search-suggestion-option-hover-color,
    var(--default-search-suggestion-option-hover-color)
  );
}

.SearchControl .Suggestion.highlighted {
  /* color: #ec2027; */
  color: var(
    --search-suggestion-option-highlighted-color,
    var(--default-search-suggestion-option-highlighted-color)
  );
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .SearchControl {
    padding: 15px;
  }
}
