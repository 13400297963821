.Home {
  position: absolute;
  min-height: 100%;
  height: 100%;
}

.Home .LeftPane {
  position: absolute;
  height: 100%;
  min-width: 400px;
  width: 400px;
  background-color: #ffffff;
  box-shadow: 3px 3px 29px 3px rgba(0, 0, 0, 0.18);
  z-index: 1;
}

.Home .LeftPane .LeftPaneContent {
  overflow-y: auto;
  height: 100%;
}

.Home .LeftPane .LeftPaneContent .tab-pane {
  height: 100%;
}

.Home .LeftPane .Footer .Copyright {
  margin: 34px 45px 0px;
}

.Home .LeftPane .Footer .Copyright::before {
  content: var(
    --footer-copyright-content,
    var(--default-footer-copyright-content)
  );
  font-family: var(
    --footer-copyright-font-family,
    var(--default-footer-copyright-font-family)
  );
  font-weight: var(
    --footer-copyright-font-weight,
    var(--default-footer-copyright-font-weight)
  );
  font-size: var(
    --footer-copyright-font-size,
    var(--default-footer-copyright-font-size)
  );
  color: var(
    --footer-copyright-font-color,
    var(--default-footer-copyright-font-color)
  );
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .Home .LeftPane {
    min-width: 100%;
    width: 100%;
  }

  .Home .LeftPane .Footer .Copyright {
    margin: 15px 30px 0px;
  }
}
