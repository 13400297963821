.Place .PlaceIcon {
  max-width: 30px;
  min-width: 30px;
  cursor: pointer;
  /* Center the tip horizontally and vertically. */
  transform: translate(-50%, -50%);
}

.Place div,
.Place div img {
  max-width: var(--pin-icon-width, var(--default-pin-icon-width));
  max-height: var(--pin-icon-height, var(--default-pin-icon-height));
}
